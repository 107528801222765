import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import Claim from "../features/claims/Claim";
import ClaimsAdminContainer from "../features/claims/ClaimsAdminContainer";
import AdminContentContainer from "../features/content/AdminContentContainer";
import CustomImageUpload from "../features/customisation/CustomImageUpload";
import CustomSiteVariables from "../features/customisation/CustomSiteVariables";
import Customisation from "../features/customisation/Customisation";
import DistributorsAdminContainer from "../features/distributors/DistributorsAdminContainer";
import EmailsList from "../features/emails/EmailsList";
import PointsSummary from "../features/emails/PointsSummary";
import EmailTemplateEditor from "../features/emails/EmailTemplateEditor";
import AdminOrder from "../features/orders/AdminOrder";
import OrdersAdminContainer from "../features/orders/OrdersAdminContainer";
import ProductsAdminContainer from "../features/products/ProductsAdminContainer";
import ProgramsListContainer from "../features/programs/ProgramsListContainer";
import Dashboard from "../features/reports/Dashboard";
import PointsExpired from "../features/reports/PointsExpired";
import PointsRedeemed from "../features/reports/PointsRedeemed";
import ProgramSnapshot from "../features/reports/ProgramSnapshot";
import StoresAdminContainer from "../features/stores/StoresAdminContainer";
import BulkUploadHistory from "../features/users/BulkUploadHistory";
import UserContainer from "../features/users/UserContainer";
import UsersAdminContainer from "../features/users/UsersAdminContainer";
import { selectUsers } from "../features/users/usersSlice";
import StoreContainer from "../features/stores/StoreContainer";
import MemberGroupAdminContainer from "../features/members/MemberGroupAdminContainer";
import MemberGroupContainer from "../features/members/MemberGroupContainer";

const useStyles = makeStyles((theme) => ({
  adminContainer: {
    backgroundColor: "#ddd",
    display: "flex",
    flexGrow: 1,
    paddingBottom: 60,
    paddingTop: 60,
    justifyContent: "center",
    // maxWidth: "100%",
  },
}));

export default function AdminContainer(props) {
  const classes = useStyles();
  const history = useHistory();
  const { userAccount, loading } = useSelector(selectUsers);

  useEffect(() => {
    if (loading == "loaded") {
      if (userAccount.roleID === 99) {
        history.push("/");
      }
    }
  }, [props.location, loading]);

  return (
    <div className={classes.adminContainer}>
      <Switch>
        <Route exact path="/admin/members" component={UsersAdminContainer} />
        <Route path="/admin/members/*" component={UserContainer} />
        <Route exact path="/admin/orders" component={OrdersAdminContainer} />
        <Route path="/admin/orders/*" component={AdminOrder} />
        <Route
          exact
          path="/admin/products"
          component={ProductsAdminContainer}
        />
        <Route
          exact
          path="/admin/distributors"
          component={DistributorsAdminContainer}
        />
        <Route exact path="/admin/stores" component={StoresAdminContainer} />
        <Route exact path="/admin/stores/*" component={StoreContainer} />
        <Route exact path="/admin/membergroups" component={MemberGroupAdminContainer} />
        <Route exact path="/admin/membergroups/*" component={MemberGroupContainer} />
        <Route exact path="/admin/programs" component={ProgramsListContainer} />
        {/* <Route path="/admin/products/*" component={AdminProduct} /> */}
        {process.env.REACT_APP_ENABLE_CLAIMS == "true" && (
          <Route exact path="/admin/claims" component={ClaimsAdminContainer} />
        )}
        {process.env.REACT_APP_ENABLE_CLAIMS == "true" && (
          <Route path="/admin/claims" component={Claim} />
        )}
        <Route
          path="/admin/customisation/content"
          component={AdminContentContainer}
        />
        {/* <Route path="/admin/emails/summary" component={PointsSummary} /> */}
        <Route path="/admin/emails" component={EmailsList} />
        <Route path="/admin/uploads" component={BulkUploadHistory} />
        <Route
          path="/admin/customisation/images"
          component={CustomImageUpload}
        />
        <Route
          path="/admin/customisation/email"
          component={EmailTemplateEditor}
        />
        <Route
          path="/admin/customisation/variables"
          component={CustomSiteVariables}
        />
        <Route path="/admin/customisation" component={Customisation} />

        <Route path="/admin/reports/redeemed" component={PointsRedeemed} />
        <Route path="/admin/reports/expired" component={PointsExpired} />
        <Route path="/admin/reports/snapshot" component={ProgramSnapshot} />
        <Route path="/admin/reports/dashboard" component={Dashboard} />
      </Switch>
    </div>
  );
}

import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import axios from "axios";
import { errorSnack } from "../snackbar/snackbarSlice";

import { uuidv4 } from "../../app/utils";
import baseURL from "../../app/utils.js";

axios.interceptors.request.use(
  (req) => {
    req.headers.common["X-Request-ID"] = uuidv4();
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export const fetchClient = createAsyncThunk(
  "clients/fetchClient",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/client`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Client data"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchClientCountry = createAsyncThunk(
  "clients/fetchClientCountry",
  async (args, thunkAPI) => {
    const config = {
      headers: { 'X-Client-Host': process.env.REACT_APP_LOCAL_URL, },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/rt/clients/country`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Client data"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateClient = createAsyncThunk(
  "clients/updateClient",
  async (args, thunkAPI) => {
    const { token, clientData } = args;
    const config = {
      headers: { 
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    };
    
    try {
      const response = await axios.put(
        `${baseURL}/v1/admin/client`,
        clientData,
        config
      );
      
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error updating Client data"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const clientsSlice = createSlice({
  name: "programs",
  initialState: {
    client: {},
    country: {},
    loading: "idle",
    message: "",
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchClient.pending, (state) => {
      state.client = {};
      state.loading = "loading";      
    });
    builder.addCase(fetchClient.fulfilled, (state, { payload }) => {
      state.client = payload;
      state.loading = "loaded";
      state.editing = true;
    });
    builder.addCase(fetchClient.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchClientCountry.pending, (state) => {
      state.country = {};
      state.loading = "loading";      
    });
    builder.addCase(fetchClientCountry.fulfilled, (state, { payload }) => {
      state.country = payload;
      state.loading = "loaded";
      state.editing = true;
    });
    builder.addCase(fetchClientCountry.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(updateClient.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(updateClient.fulfilled, (state, { payload }) => {
      state.client = payload;
      state.loading = "loaded";
      state.editing = true;
    });
    builder.addCase(updateClient.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function

export const selectClients = createSelector(
  (state) => ({    
    client: state.clients.client,
    country: state.clients.country,
    loading: state.clients.loading,
    message: state.clients.message,
    error: state.clients.error,
  }),
  (state) => state
);
export default clientsSlice.reducer;